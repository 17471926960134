import axios from "axios"
import { Link, navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react"
import { media } from "styled-bootstrap-grid"
import styled from "styled-components"
import ArrowRightIcon from "../components/Icons/ArrowRightIcon"
import Seo from "../components/Layout/Seo"
import Site from "../components/Layout/Site"
import HeavyLifting from "../components/Shared/HeavyLifting"
import Button from "../components/UI/Button"
import Element from "../components/UI/Element"
import { Container, Row, Col } from "../components/UI/Grid"
import ScaledElement from "../components/UI/ScaledElement"
import ScaledImage from "../components/UI/ScaledImage"
import { Section } from "../components/UI/Section"
import { Heading, Paragraph } from "../components/UI/Typography"

const Products = ({ location }) => {
  const [processing, setProcessing] = useState(true)
  const [productTypes, setProductTypes] = useState(null)
  const isBrowser = typeof window !== "undefined"

  useEffect(() => {
    axios
      .get(
        `${
          isBrowser &&
          window?.location?.origin === "https://boeingmhc.digiteer.dev"
            ? process.env.GATSBY_STAGING_API_URL
            : process.env.GATSBY_API_URL
        }/product-types`
      )
      .then((res) => {
        if (res.data) {
          setProductTypes(res.data)
        } else {
          navigate("/404")
        }
      })
      .finally(() => setProcessing(false))
  }, [location.pathname])

  return (
    <Site>
      <Seo title="Products" />
      <ScaledElement scaleWidth={1440} scaleHeight={240} fullWidth>
        <ScaledImage bg={true}>
          <StaticImage
            src="../images/banner-products.jpg"
            alt="banner"
            layout="fullWidth"
          />
        </ScaledImage>
      </ScaledElement>
      <Section>
        <Container>
          <Row
            justifyContent="center"
            textAlign="center"
            mb={{ _: 10, lg: 20 }}
          >
            <Col col={{ lg: 5 }}>
              <Heading as="h1" fs="h1" mb={4}>
                Products
              </Heading>
              <Paragraph>
                We offer value-added material handling products and related
                services that let our customers be more productive to the
                ever-changing global marketplace.
              </Paragraph>
            </Col>
          </Row>

          {!processing &&
            productTypes.map((pt, idx) => (
              <ProductType key={pt.id}>
                <Row>
                  <Col
                    col={{ md: 4, lg: 3 }}
                    order={{ md: idx % 2 === 1 && 2 }}
                  >
                    <ScaledImage scaleWidth={380} scaleHeight={400}>
                      <img src={pt.thumbnail} alt={pt.name} />
                    </ScaledImage>
                  </Col>
                  <Col col={{ md: 8, lg: 9 }}>
                    <ProductContent>
                      <Element width="100%">
                        <Heading fs="h2" color="#fff" mb={4}>
                          {pt.name}
                        </Heading>
                        <Paragraph>{pt.description}</Paragraph>
                      </Element>

                      <ButtonProduct
                        variant="brand"
                        as={Link}
                        to={`/product-types/${pt.slug}`}
                        size="lg"
                      >
                        <Element as="span" mr={4}>
                          View {pt.name}
                        </Element>
                        <ArrowRightIcon />
                      </ButtonProduct>
                    </ProductContent>
                  </Col>
                </Row>
              </ProductType>
            ))}
        </Container>
      </Section>
      <HeavyLifting />
    </Site>
  )
}

const ProductType = styled.div`
  background-color: var(--brand);
  color: #fff;

  & + & {
    margin-top: 32px;
  }
`

const ProductContent = styled.div`
  max-width: 440px;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${media.lg`
   padding: 40px 60px;
  `};
`

const ButtonProduct = styled(Link)`
  min-height: 48px;
  display: inline-flex;
  background-color: var(--brand);
  color: #fff;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;

  svg {
    transition: var(--transition);
  }

  &:hover {
    color: #fff;

    svg {
      transform: translateX(4px);
    }
  }
`

export default Products
